import "./App.css";
import NationWideExpress from "./img/external-adverts/nationwide-express-advert.png";
import NationalRoadSafetyCouncil from "./img/external-adverts/national-road-safety-council-advert.png";
import WebsiteSoftwareDevelopment from "./img/internal-adverts/website-and-software-development-available.png";
// import third from "./img/1200x455.png";
function App() {
  const handleClick = (link: string) => {
    const url = link;
    if (url) {
      window.open(url, "_blank");
    }
  };

  // Modify adverts listing here
  const AdvertsList = [
    {
      name: "National Road Safety Council -- Advert",
      image: NationalRoadSafetyCouncil,
      link: "http://www.nationalroadsafetycouncil.org.jm/aboutus.htm",
      show: true,
    },
    {
      name: "Web Mapping Services -- Advert",
      image: WebsiteSoftwareDevelopment,
      link: "https://main.monagis.com/wp-content/uploads/2023/09/MGIPortfolio_2023_V2.pdf",
      show: false,
    },
    {
      name: "Nation Wide Express -- Advert",
      image: NationWideExpress,
      link: "http://nationwidecitytocity.com",
      show: false,
    },
  ];
  return (
    <div className="w-screen h-screen">
      <div className="flex">
        <div className="flex mb-[-1px]">
          {/* <div className="w-1/6">
            <img alt="test-dimensions" src={third} />
          </div> */}
          {AdvertsList.map((advert, index) =>
            advert.show === false ? (
              <div
                key={index}
                className="flex flex-col w-3/6 hover:opacity-20"
                onClick={() => handleClick(advert.link)}
              >
                <img
                  alt={advert.name}
                  className={advert.name}
                  src={advert.image}
                />
              </div>
            ) : null
          )}
        </div>
      </div>

      <div className="grid grid-cols-12 w-screen h-screen">
        <div className="col-span-12">
          <iframe
            title="crash-map-iframe"
            className=" w-full h-full"
            src="https://panda-gis.maps.arcgis.com/apps/opsdashboard/index.html#/e7088e4590274cb7be6af5811c130c8b"
          ></iframe>
        </div>
        {/* <div className=' col-span-3 overflow-y-auto'>
        <div className=' flex flex-col'> */}
        {/* <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('GIS Software '); setMedia(ad_1); setWebsite("https://www.main.monagis.com"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("image") }}>
            <img src={ad_6} />
          </button> */}
        {/* <hr className=" h-3 bg-black/80" />
          <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('Drone Services '); setMedia(video_1); setWebsite("https://www.main.monagis.com"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("video") }}>
            <video src={video_1} autoPlay muted loop />
          </button>
          <hr className=" h-3 bg-black/80" />
          <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('Social Survey Services '); setMedia(ad_5); setWebsite("https://main.monagis.com/announcing-mgis-advanced-social-survey-services/"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("image") }}>
            <img src={ad_5} />
          </button>
          <hr className=" h-3 bg-black/80" />
          <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('Geospatial Training '); setMedia(ad_4); setWebsite("https://www.main.monagis.com"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("image") }}>
            <img src={ad_4} />
          </button>
          <hr className=" h-3 bg-black/80" />
        </div>
        {show && <Modal setShow={setShow} title={title} media={media} website={website} mail={mail} facebook={facebook} call={call} mediaType={mediaType} />}
      </div> */}
      </div>
    </div>
  );
}

export default App;
